.page-wrap{
	// height: 100vh;
	display: flex;
	flex-direction: column
}

.page-content{
	padding: 30px 0;
}

.sidebar__content--desktop{ display: none; }

.icon-arrow{
	position: relative;
	display: block;
	width: 14px; height: 8px;
	@include transition();

	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
	}

	&:before{
		width: 10px; height: 2px;
		left: 0; top: 3px;
		background: $c-red;
	}

	&.icon-arrow--right{

		&:after{
			left: 10px; top: 0;
			width: 0; height: 0;
			border: {
				left: solid 4px $c-red;
				top: solid 4px transparent;
				bottom: solid 4px transparent;
			}
		}
	}
}

.header{
	position: relative;
	display: flex;
	width: 100%;
	padding: 20px 0;
	border-bottom: $border;
	@include transition();
}

.search{
	margin-bottom: 40px;
}

.search__form{
	position: relative;
}

.search__icon{
	position: absolute;
	left: 20px; top: 50%;
	transform: translateY(-50%);
	display: block;
	width: 20px; height: 20px;
}

.search__toggle{
	display: none;
}

.search__input{
	border: $border;
	width: 100%;
	padding: 20px 20px 20px 50px;
	outline: none;
	font: {
		size: 1.5rem;
	}
	color: $c-gray-dark;

	&::placeholder{
		font-family: $f-bold;
		color: $c-gray-light;
	}
}

#main{
	background: #fff;

	&.is-scrolled{
		background: red;
	}
}

.main-content{
	padding: 0 30px;
	flex: 1;
	display: flex;
	// overflow: auto;
}

.main-content__block{
	width: 100%;
}

.logo-wrap{
	width: 100%;
}

.logo{
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0; padding: 0 20px;
	font: {
		size: 1.625rem;
	}
	text: {
		transform: uppercase;
		align: center;
	}
}

.logo__link{
	color: $c-gray;
}

.logo__secondary{
	text-transform: none;
	font: {
		size: 1.125rem;
	}
}

.header__url-label{
	position: absolute;
	left: 0; bottom: 0; right: 0;
	height: 80px;
	font: {
		size: 1.125rem;
	}
	border-top: $border;
}

.header__url-text{
	position: absolute;
	left: 50%; top: 50%;
	transform: translate(-50%, -50%);
}

.search-form{
	margin-top: 120px;
}

.search-form-wrap{
	width: 100%;
	padding: 40px 40px 150px;
}

.search-form__title{
	text-align: center;
	font: {
		size: 1.75rem;
	}
	padding: 20px 0;
}

.search-form__fields{
	position: relative;
	display: flex;
	flex-direction: column-reverse;
}

.search-form__input,
.search-form__select{
	display: block;
	width: 100%; height: 50px;
	padding: 10px 12px;
}

.search-form__input-wrap{
	position: relative;
	margin-bottom: 20px;
}

.search-form__input{
	flex: 1;
	border: $border;
	outline: none;
}

.search-form__submit{
	position: absolute;
	right: 0; top: 0; bottom: 0;
	border: none;
	padding: 0 20px; margin: 0;
	background: transparent;

	img{
		display: block;
	}
}

.search-form__select{
	width: 185px;
	border: none;
}

.list-header{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 30px 0;
}

.list-header__title{
	margin: {
		top: 10px; bottom: 30px;
	}
	font: {
		size: 2.125rem;
	}
	color: $c-gray;
	text-align: center;
}

.list-header__filters{
	display: flex;
	flex-direction: column;

	.filter{
		margin-bottom: 20px;
	}
}

.production__header{
	padding: 20px 0 80px;
	border-bottom: $border;
}

.production__surtitle,
.production__subtitle{
	text-transform: uppercase;
}

.production__surtitle{
	font: {
		size: 1.5rem;
	}
}

.production__subtitle{
	font: {
		size: 1.25rem;
	}
}

.production__title{
	font: {
		size: 3rem;
	}
	padding: 10px 0;
}

.production__series{
	padding-top: 40px;
	display: flex;
	flex-wrap: wrap;

	li{
		margin-right: 12px;
		margin-bottom: 12px;

		&:last-child{ margin-right: 0; }
	}
}

.production__details{
	display: flex;
	flex-wrap: wrap;
	margin-right: -20px;
}

.detail-block{
	width: 50%;
	padding: {
		top: 20px; right: 20px; bottom: 20px;
	}
}

.detail-block__headline{
	font-family: $f-med;
}

.detail-block__content{
	display: block;
	padding-top: 12px;
	font: {
		size: 1.125rem;
		family: $f-bold;
	}

	a{
		color: $c-red;
		font: {
			family: $f-bold;
		}
	}
}

@include bp(min, $bp-lg) {

	.production__title{
		font-size: 5.125rem;
	}
}

.react-tabs{
	margin-top: 50px;
}

.react-tabs__tab-list{
	display: flex;
	border: {
		left: $border;
		bottom: $border;
	}
	margin-bottom: 20px;
}

.react-tabs__tab{
	padding: 12px 20px;
	border: {
		right: $border;
		top: $border;
	}
	cursor: pointer;
	@include transition();

	&:hover,
	&:focus{
		color: $c-red;
	}

	&.react-tabs__tab--selected{
		background: $c-black;
		color: $c-white;

		&:hover{
			color: $c-white;
			cursor: default;
		}
	}
}

.react-select__control{
	position: relative;
	min-width: 200px;
	height: 50px;
	border: $border;
}

.react-select__value-container{
	display: flex;
	height: 100%;
	align-items: center;
	padding: 0 20px;
}

.react-select__indicators{
	position: absolute;
	right: 10px; top: calc(50% + 5px);
	transform: translateY(-50%);
}

.react-select__placeholder{
	text-transform: uppercase;
	font: {
		family: $f-bold;
	}
}

.react-select__menu{
	position: absolute;
	width: 200px;
	z-index: 99;
	border: $border;
	border-top: none;
	background: $c-white;
}

.react-select__menu-list{
	max-height: 278px;
	overflow-y: scroll;
}

.react-select__option{
	position: relative;
	z-index: 2;
	border-bottom: $border;
	padding: 14px 20px;
	font: {
		family: $f-bold;
		size: 1.125rem;
	}
	cursor: pointer;
	color: $c-black;
	@include transition();

	&:after{
		content: '';
		position: absolute;
		z-index: -1;
		left: 0; top: 0;
		display: block;
		width: 0; height: 100%;
		background: $c-red;
		@include transition();
	}

	&:last-child{
		border-bottom: none;
	}

	&:hover{
		color: $c-white;

		&:after{
			width: 100%;
		}
	}
}

.pagination{
	margin: 30px 0;
}

.pagination__list{
	display: flex;
	justify-content: center;
}

.pagination__item{
	margin: 0 8px;
	font: {
		family: $f-bold;
		size: 1.125rem;
	}
}

.pagination__link{
	display: block;
	text-align: center;

	&.is-active{
		min-width: 25px;
		padding: {
			left: 8px; right: 8px;
		}
		background: $c-black;
		color: $c-white;
	}
}

@include bp(max, $bp-lg) {

	.nav-menu,
	.main-content{
		width: 100%;
	}

	.nav-menu{
		position: fixed;
		z-index: 98;
		left: 0; top: 0;
		width: 100%; height: 100%;
		padding: 150px 30px 30px;
		background: $c-white;

		transform: translateX(100%);
		visibility: hidden;
		box-shadow: -4px 0 0 $c-black;
		@include transition();

		&.is-active{
			visibility: visible;
			transform: translateX(0%);
		}
	}

	.nav-menu__list{
		display: flex;
		flex-direction: column;
	}

	.nav-menu__item{

		a{
			position: relative;
			display: block;
			padding: 30px 0;
			font: {
				family: $f-bold;
				size: 1.75rem;
			}
			text-align: center;

			&:hover,
			&:focus{

				&:before{
					height: 8px;
				}
			}
		}
	}

	.nav-toggle{
		position: absolute;
		z-index: 99;
		display: block;
		height: 25px; width: 35px;
		right: 30px; top: 50%;
		transform: translateY(-50%);
		border: none;
		background: transparent;
		outline: none;

		.nav-toggle__icon{
			position: absolute;
			right: 0; top: 10px;
			height: 5px; width: 35px;
			background: $c-black;
			transition-delay: 0.2s;

			&:before,
			&:after{
				content: '';
				display: block;
				width: 25px; height: 5px;
				background: $c-black;
				position: absolute;
				right: 0;
				transform: translateZ(0) scale(1.0, 1.0);
				transition:
					transform 0.2s,
					top 0.2s 0.2s,
					width 0.2s 0.2s;
			}

			&:before{
				top: 10px;
			}

			&:after{
				top: -10px;
			}
		}

		&.is-active{

			.nav-toggle__icon{
				background: rgba($c-white, 0.0);

				&:before,
				&:after{
					transition:
						width 0.2s,
						top 0.2s 0.0s,
						transform 0.2s 0.2s;
				}

				&:before{
					width: 35px;
					top: 0;
					transform: rotate(45deg) translateZ(0) scale(1.0, 1.0);
				}

				&:after{
					width: 35px;
					top: 0;
					transform: rotate(-45deg) translateZ(0) scale(1.0, 1.0);
				}
			}
		}
	}
}

@include bp(min, $bp-lg) {

	.page-wrap{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		padding-top: 100px;

		.logo-wrap,
		.sidebar{
			width: 50%;
		}

		.sidebar{
			position: relative;
			height: 100vh;
		}

		.sidebar__content--mobile{ display: none; }

		.nav-menu,
		.main-content{
			width: 50%;
		}


		&.person-single{
			.logo-wrap, .sidebar{ width: percentage(1/3); }
			.nav-menu, .main-content{ width: percentage(2/3); }
		}

		&.page-404,
		&.person-archive,
		&.production-archive,
		&.search-results{
			.logo-wrap, .sidebar{ width: percentage(1/4); }
			.nav-menu, .main-content{ width: percentage(3/4); }
		}

		&.production-single{
			.logo-wrap, .sidebar{ width: 42%; }
			.nav-menu, .main-content{ width: 58%; }
		}
	}

	.production__header{
		padding: 100px 0 80px;
	}

	.header{
		width: 100%;
		position: fixed;
		z-index: 101;
		left: 0; top: 0; right: 0;
		padding: {
			top: 0; bottom: 0;
		}
		border-bottom: none;
    background: $c-white;
	}

	.sidebar,
	.main-content{
		min-height: 100%;
	}

	.logo-wrap,
	.sidebar__content{
		border-right: $border;
	}

	.sidebar__content{
		min-height: calc(100vh - 100px);
		display: flex;
		flex-direction: column;
	}

	.main-content{
		flex: 1;
	}

	.logo-wrap,
	.nav-menu{
		height: 100px;
	}

	.logo-wrap{
		border-bottom: $border;
    align-items: center;
    display: flex;
	}

	.nav-menu{
		width: 100%;
		border: {
			bottom: $border;
		}
		background: $c-white;
		display: flex;
		align-items: center;
		padding: 0 45px 0 10px;
	}

	.nav-menu__list{
		display: flex;
		margin-left: auto;
	}

	.nav-menu__item{
		margin-right: 60px;

		&:last-child{ margin-right: 0; }

		a{
			position: relative;
			display: block;
			padding: 38px 30px;
			font: {
				family: $f-bold;
				size: 1.125rem;
			}

			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0; top: 0; right: 0;
				height: 0; width: 100%;
				background: $c-red;
				@include transition(0.15s);
			}

			&.active,
			&:hover{
				&:before{ height: 8px; }
			}
		}
	}

	.search{
		position: relative;
		margin-bottom: 0;
	}

	.search__toggle{
		width: 20px; height: 20px;
		background: $c-red;
		border: none;
	}

	.search__input{
		border: none;
	}

	.nav-toggle{
		display: none;
	}

	.search-form__title{
		font: {
			size: 2.25rem;
		}
	}

	.search-form__fields{
		flex-direction: row;
		align-items: flex-start;
		padding: 0 30px;

		.react-select-container{
			width: 200px;
		}

		.react-select__control{
			height: 50px;
		}
	}

	.search-form__input-wrap{
		flex: 1;
	}

	.search-form__input{
		border-left: none;
	}

	.list-header{
		flex-direction: row;
	}

	.list-header__filters{
		margin-left: auto;
		display: flex;
		flex-direction: row;

		.filter{
			margin-left: 30px;

			&:first-child{ margin-left: 0; }
		}
	}
}

.person{

}

.person__name{

}

.person__secondary{
	display: block;
	padding-top: 10px;
	font: {
		family: $f-bold;
	}
	text-transform: uppercase;
}

@include bp(min, $bp-lg) {

	.person__header{
		padding: 80px 0 50px;
	}

	.person__name{
		font: {
			size: 4.625rem;
		}
	}

	.person__secondary{
		font: {
			size: 1.25rem;
		}
	}
}





