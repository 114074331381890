.search-results__block{
	padding: 30px 0 80px 0;
}

.search-results__header{
	padding-bottom: 30px;
}

.search-results__none{
	padding: 60px 0;
}

.loading-content{
	padding: 60px 0;
}