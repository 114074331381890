@import 'fonts';

$f-bold: 'Biennale-Bold', sans-serif;
$f-boldit: 'Biennale-BoldIt', sans-serif;
$f-med: 'Biennale-Medium', sans-serif;
$f-medit: 'Biennale-MediumIt', sans-serif;

body{
	font-family: $f-med;
	line-height: 1.6em;
}

h1, h2, h3, h4, h5, h6{
	margin: 0; padding: 0;
	font: {
		family: $f-bold;
		weight: normal;
	}
	line-height: 1.2em;
}

.header-secondary{
	text-transform: uppercase;
	color: $c-gray-dark;
	letter-spacing: 0.12em;
	font: {
		size: 1.125rem;
	}
}

a{
	color: $c-black;
	text-decoration: none;
}

ul,
ol{
	list-style: none;
	margin: 0; padding: 0;
}

.c-red{
	color: $c-red;
}

.pill{
	display: inline-block;
	padding: 10px 20px;
	background: $c-black;
	border-radius: 24px;
	color: $c-white;
	font: {
		family: $f-bold;
		size: 1.125rem;
	}
	@include transition();

	&:hover,
	&:focus{
		background: $c-red;
	}
}

button{
	padding: 0; margin: 0;
	border: none;
	cursor: pointer;
	outline: none;
}

.button{
	border: none;
	background: $c-black;
	color: $c-white;
	@include transition();

	&.button--full{
		width: 100%;
		display: block;
		padding: 20px;
		text-align: center;
		font: {
			family: $f-bold;
			size: 1.375rem;
		}
	}

	&:hover{
		background: $c-red;
	}
}

.link{
	position: relative;
	font: {
		family: $f-bold;
	}
	text-transform: uppercase;
	letter-spacing: 0.12em;
	color: $c-gray-dark;
	@include transition();

	&:before{
		content: '';
		position: absolute;
		left: 0; bottom: -2px;
		display: block;
		width: 0%; height: 3px;
		background: $c-black;
		@include transition();
	}

	&:hover{
		color: $c-red;

		&:before{
			width: 100%;
		}
	}
}