@font-face {
  font-family: 'Biennale-Bold';
  src: url('../../webfonts/Biennale-Bold/font.woff2') format('woff2'), url('../../webfonts/Biennale-Bold/font.woff') format('woff');
}
@font-face {
  font-family: 'Biennale-BoldIt';
  src: url('../../webfonts/Biennale-BoldIt/font.woff2') format('woff2'), url('../../webfonts/Biennale-BoldIt/font.woff') format('woff');
}
@font-face {
  font-family: 'Biennale-Medium';
  src: url('../../webfonts/Biennale-Medium/font.woff2') format('woff2'), url('../../webfonts/Biennale-Medium/font.woff') format('woff');
}
@font-face {
  font-family: 'Biennale-MediumIt';
  src: url('../../webfonts/Biennale-MediumIt/font.woff2') format('woff2'), url('../../webfonts/Biennale-MediumIt/font.woff') format('woff');
}