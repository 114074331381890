@mixin bp($dir, $point) {
  @if $dir == min {
    @media ( min-width: $point ) { @content; }
  } @else {
    @media ( max-width: $point - 1 ) { @content; }
  }
}

@mixin transition($time: 0.3s, $ease: ease) {
  transition: $time $ease;
}

@mixin link() {
	display: inline-block;
	position: relative;
	font: {
		family: $f-bold;
	}
	color: $c-red;
	@include transition();

	&:before{
		content: '';
		position: absolute;
		left: 0; bottom: -2px;
		width: 0%; height: 3px;
		background: $c-red;
		@include transition();
	}

	&:hover{
		color: $c-black;

		&:before{
			width: 100%;
		}
	}
}

@mixin link-rev() {
	@include link;
	color: $c-black;

	&:before{
		background: $c-black;
	}

	&:hover{
		color: $c-red;
	}
}