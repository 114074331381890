
table{
	table-layout: fixed;
	text-align: left;
	width: 100%;
	padding-bottom: 60px;
	font: {
		size: 0.875rem;
	}

	a{
		@include link;
	}
}

thead{

	th{
		padding: 5px 0 20px;
	}
}

tbody{

	td{
		padding-bottom: 15px;
	}
}

td,
th{
	overflow: hidden;
	line-height: 1.2em;
	overflow-wrap: break-word;
}

th{
	color: $c-gray-dark;
	font: {
		family: $f-bold;
	}
	text-transform: uppercase;
}

.th-people, .td-people,
.th-role, .td-role{
	width: 50%;
}

@include bp(min, $bp-lg) {

	table{
		font: {
			size: 1.125rem;
		}
	}
}