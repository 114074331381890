.credit{
	margin-top: auto;
	width: 100%; height: 80px;
	border-top: $border;
	display: flex;
	align-items: center;
	justify-content: center;
}

.credit__text{
	font: {
		size: 1.125rem;
	}
}

.credit__link{

	&:hover{

		.credit__text-name{
			color: $c-red;

			&:before{ width: 100%; }
		}
	}
}

.credit__text-name{
	@include link-rev;
}