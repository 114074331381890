.params-list-wrap{
	width: 100%;
}

.params-list{
	display: flex;
}

.params-list__item{
	display: flex;
	align-items: center;
	padding: 10px 20px; margin-right: 10px;
	background: $c-red;
	border-radius: 25px;
	color: $c-white;
	font: {
		size: 1rem;
		family: $f-bold;
	}
}

.params-list__item-close{
	width: 20px; height: 20px;
	background: transparent;
	margin-left: 10px;
	position: relative;

	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
		left: 50%; top: 50%;
		width: 3px; height: 16px;
		background: $c-white;
	}

	&:before{
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after{
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}