.productions-list{

}

.list-block{
	position: relative;
	display: flex;
	border-bottom: $border;
	margin: 0 -30px 30px -30px;
	padding: 0 30px 20px;

	&:before{
		content: '';
		position: absolute;
		left: 0; bottom: -4px;
		display: block;
		width: 0%; height: 4px;
		background: $c-red;
		@include transition();
	}

	&:hover{

		&:before{
			width: 100%;
		}

		.list-block__title{
			color: $c-red;
		}

		.list-block__image-letter{
			background: $c-red;
		}
	}
}

.list-block__header{
	width: 60%;
	display: flex;
}

.list-block__title{
	font: {
		size: 1.5rem;
	}
	@include transition();
}

.list-block__season{
	display: block;
	padding-top: 5px;
	text-transform: uppercase;
	font: {
		size: 1.125rem;
	}
	color: #4A4A4A;
}

.list-block__image,
.list-block__image-letter{
	width: 92px; height: 100px;
	display: block;
	margin-right: 30px;
}

.list-block__image-letter{
	background: $c-black;
	color: $c-white;
	text-align: center;
	line-height: 102px;
	font: {
		family: $f-bold;
		size: 3.75rem;
	}
	@include transition();
}

.list-block__seriestags,
.list-block__typetags{
	width: 20%;
}

.list-block__list{
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;

	li{
		margin-right: 4px;
		color: #4A4A4A;
		font: {
			size: 1rem;
		}
		text-transform: uppercase;

		&:after{
			content: ',';
		}

		&:last-child{
			margin-right: 0;

			&:after{ content: ''; }
		}
	}
}

@include bp(max, $bp-lg) {

	.list-block{
		flex-direction: column
	}

	.list-block__header,
	.list-block__seriestags,
	.list-block__typetags{
		width: 100%;
	}

	.list-block__image-wrap{
		display: none;
	}
}

@include bp(min, $bp-lg) {
	.list-block{
		margin: 0 0 30px 0; padding: 0;
	}
}