.production-sidebar{

	.sidebar__image-wrap{
		// margin-bottom: 4px;
	}
}

.sidebar__image-link,
.sidebar__gallery-image-wrap{
	display: block;
	background: $c-red;

	img{
		@include transition();
	}

	&:hover{

		img{ opacity: 0.35;}
	}
}

.sidebar__image-link{
	display: block;
	width: 100%;
}

.sidebar__image{
	display: block;
	width: 100%; height: auto;
}

.cast-person{
	display: flex;
	align-items: center;
}

.cast-person__image,
.cast-person__letter{
	display: block;
	margin-right: 15px;
	flex: 0 0 46px; height: 50px;
}

.cast-person__letter{
	background: $c-black;
	color: $c-white;
	font: {
		size: 1.5rem;
	}
	text-align: center;
	line-height: 48px;
}

.cast-person__link{

	.icon-arrow{
		opacity: 0;
	}

	&:hover,
	&:focus{

		&:before{
			width: 100%;
		}

		.icon-arrow{
			opacity: 1;
			transform: translateX(5px);
		}
	}
}