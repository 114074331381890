
.sidebar{
	// position: sticky;
	// bottom: 0;
}

.person-sidebar {
	
	.sidebar__image-wrap{
		border-bottom: $border;
	}
}

.sidebar__image{
	width: 100%; height: auto;
	display: block;
}

.sidebar__tag-list{
	padding: 30px;
	display: flex;
	flex-wrap: wrap;
}

.sidebar__tag-item{
	margin: {
		right: 10px; bottom: 10px;
	}
}

.person-table__production{
	width: percentage(2/3);
	padding-right: 20px;
}