.modal{
	position: fixed;
	z-index: 101;
	left: 0; top: 0;
	height: 100%; width: 100%;
	background: rgba($c-black, 0.6);
}

.modal__close{
	position: absolute;
	z-index: 102;
	right: 0; top: 0;
	width: 50px; height: 50px;
	border: {
		left: $border-white;
		bottom: $border-white;
	}
	background: $c-black;

	.icon--close{
		width: 100%; height: 100%;

		&:before,
		&:after{
			content: '';
			position: absolute;
			left: 50%; top: 50%;
			display: block;
			width: 30px; height: 4px;
			background: $c-white;
		}

		&:before{ transform: translate(-50%, -50%) rotate(45deg); }
		&:after{ transform: translate(-50%, -50%) rotate(-45deg); }
	}
}

.sidebar__gallery{
	display: flex;
	flex-wrap: wrap;
}

.sidebar__gallery-link{
	width: 50%;
	border: none;
	padding: 0 2px 4px 0; margin: 0;
	background: transparent;

	&:nth-child(2n) {
		padding: 0 0 4px 2px;
	}
}

.sidebar__gallery-image{
	display: block;
	width: 100%; height: auto;
}

.slider-wrap{
	width: 100%; height: 100%;
	margin: 0 auto;
	padding: 20px;

	img{ width: 100%; height: auto; }
}

.slick-slider{
	position: relative;
}

.featured-slider-wrap{
	height: calc(100% - 100px);
	margin-bottom: 20px;

	.slick-slide{
		opacity: 0;
		pointer-events: none;
		@include transition();

		&.slick-active{
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.featured-slider{
	height: 100%;

	.slick-list,
	.slick-track,
	.slick-slide,
	.slick-slide > div{
		height: 100%;
	}
}

.featured-slider__block{
	height: 100%;
}

.featured-slider__content{
	position: relative;
	display: inline-block;
	left: 50%;
	transform: translateX(-50%);
	border: $border-white;
}

.featured-slider__details{
	position: absolute;
	left: 0; bottom: 0; right: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.featured-slider__caption{
	display: block;
	width: 100%;
	padding: 8px;
	background: $c-black;
	border-top: $border-white;
	color: $c-white;
	font: {
		size: 1.125rem;
	}
	text-align: center;
}

.featured-slider__detail-counter{
	color: $c-white;
	padding: 10px 15px;
}

.control-slider-wrap{
	height: 60px;
	overflow: hidden;
	position: relative;
	border: $border-white;
	padding: {
		left: 56px;
		right: 52px;
	}

	.slick-slider,
	.slick-list,
	.slick-track{
		height: 100%;
	}
}

.control-slider{

	.slick-arrow{
		display: block;
		width: 56px;
		position: absolute;
		z-index: 102;
		top: 0; bottom: 0;
		overflow: hidden;
		text-indent: -99px;
		background: $c-black;

		&.slick-prev{
			left: -56px;
			border-right: $border-white;
		}
		
		&.slick-next{
			right: -52px;
			border-left: $border-white;
		}

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			display: block;
			width: 20px; height: 20px;
			border: {
				left: $border-white;
				bottom: $border-white;
			}
		}

		&.slick-prev{

			&:before{
				left: 20px; 
				transform: translateY(-50%) rotate(45deg);
			}
		}

		&.slick-next{

			&:before{
				right: 20px;
				transform: translateY(-50%) rotate(-135deg);
			}
		}
	}

	.slick-slide{
		position: relative;
		cursor: pointer;

		> div { height: 100%; }

		&:before{
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
			left: 4px; top: 4px; right: 8px; bottom: 4px;
			border: $border-white;
			opacity: 0;
			@include transition();
		}

		&.slick-current{

			&:before{
				opacity: 1;
			}
		}
	}
}

.control-slider__block{
	position: relative;
	overflow: hidden;
	border-right: $border-white;
	height: 100%;

	img{
		width: auto; height: auto;
		min-width: 100%; min-height: 100%;
		position: absolute;
		left: 50%; top: 50%;
		transform: translate(-50%, -50%);
	}
}

@include bp(min, $bp-lg) {

	.slider-wrap{
		width: 80%;
		padding: 70px 0;
	}

	.featured-slider-wrap{
		height: calc(100% - 150px);
	}

	.featured-slider__content{
		height: 100%;

		img{
			height: 100%; width: auto;
		}
	}

	.control-slider-wrap{
		height: 150px;
	}
}