*, :before, :after{ box-sizing: border-box; }
html{ overflow-y: scroll; }

@import 'lib/normalize.scss';

@import 'base/variables';
@import 'base/mixins';

@import 'global/typography';
@import 'global/page-wrap';
@import 'global/credit';
@import 'global/modal';
@import 'global/tables';
@import 'global/params-list';

@import 'views/person';
@import 'views/production';
@import 'views/productions';
@import 'views/search-results';