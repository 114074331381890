$c-black: #000000;
$c-white: #FFFFFF;
$c-gray: #4A4A4A;
	$c-gray-dark: #303030;
	$c-gray-light: #B6B6B6;
$c-red: #B60012;

$border: solid 4px $c-black;
$border-white: solid 4px $c-white;

$indent: 60px;

$bp-md: 760px;
$bp-lg: 900px;